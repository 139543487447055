import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PcHeader from '../../components/pcHeader';
import PcFooter from '../../components/pcFooter';
import PcBanner from '../../components/home/banner';
import './index.scss';
import cs from 'classnames';
import OpenAccount from '../../components/home/openAccount';
import AccountSearch from '../../components/home/accountSearch';
import Http from '../../utils/fetch';
import Guid from '../../utils/guid';
import {
  API_POST_PNG,
  API_POST_SEND_CODE,
  API_POST_CITY_DATA,
  API_POST_ALL_STORE,
  API_POST_SUBMIT,
  API_POST_USER_SEARCH
} from '../../config/api';
import { DepartmentItemResultType, DepartmentResultType, ImgResultType, ProvinceResultType } from './interface';
import { message } from 'antd';
import { OpenAccountSubmitParams } from '../../components/home/openAccount/interface';
import { IMG_ORIGIN_ARROW, IMG_YELLOW_ARROW } from '../../config/img';
import IMG_CARD from '../../assets/imgs/oppentAccount.3578dd74.png';
import IMG_CARD_SEARCH from '../../assets/imgs/selectAccount.a45c3ce4.png';

const Home = (props: any) => {
  const navigate = useNavigate();
  const pageRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [departmentList, setDepartmentList] = useState<DepartmentItemResultType[]>([]);
  const [province, setProvince] = useState<ProvinceResultType[]>([]);

  useEffect(() => {
    getProvince();
    getDepartment();

    // 滚动
    const $wrap = pageRef?.current as HTMLDivElement | null;
    $wrap?.addEventListener('scroll', handleScroll);
    return () => {
      $wrap?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // 图形验证码
  const getValidImg = async (sessionId: string) => {
    const res: ImgResultType = await Http({
      url: API_POST_PNG,
      method: 'post',
      loading: false,
      body: {
        session_id: sessionId,
      },
    });
    if (res?.image_stream) {
      return res.image_stream;
    } else {
      message.error('获取图片验证码失败');
      return '';
    }
  };
  // 获取手机验证码
  const getMessageCode = async (sessionId: string, phone: string, verifyImg: string) => {
    const res: any = await Http({
      url: API_POST_SEND_CODE,
      method: 'post',
      loading: false,
      returnAll: true,
      body: {
        mobile: phone,
        verifycode: verifyImg,
        session_id: sessionId,
      },
    });
    if (res?.code === 0) {
      message.success('验证码已发送');
      return true;
    } else {
      message.error(res?.msg || '验证码无效');
      return false;
    }
  };
  // 显示省市
  const getProvince = async () => {
    const res: ProvinceResultType[] = await Http({
      url: API_POST_CITY_DATA,
      method: 'post',
      loading: false,
    });
    if (res?.length) {
      setProvince(res || []);
    } else {
      message.error('获取省市数据失败');
    }
  };
  // 获取营业部
  const getDepartment = async () => {
    const res: DepartmentResultType = await Http({
      url: API_POST_ALL_STORE,
      method: 'post',
      loading: false,
    });
    if (res?.departmentList) {
      setDepartmentList(res.departmentList || []);
    } else {
      message.error('获取营业部数据失败');
    }
  };
  // 开户提交按钮
  const getOpenAccount = async (obj: OpenAccountSubmitParams) => {
    const res: any = await Http({
      url: API_POST_SUBMIT,
      method: 'post',
      loading: true,
      returnAll: true,
      body: {
        session_id: obj.sessionId,
        verifycode: obj.varifyImg || '',
        mobilecode: obj.messageCode || '',
        name: obj.userName || '',
        idcardno: obj.idCard || '',
        province: obj.provinceValue || '',
        province_value: obj.provinceId || '',
        city: obj.cityValue || '',
        city_value: obj.cityId || '',
        address: obj.detailedAddress || '',
        telephone: obj.phoneNum || '',
        departmentid: obj.departmentId || '',
      },
    });
    if (res?.code === 0) {
      message.success(res?.msg || '提交申请成功');
      return true;
    } else {
      message.error(res?.msg || '提交申请失败');
      return false;
    }
  };
  // 仿真开户查询
  const getOpenSearch = async (sessionId: string, idCard:string,varifyImg:string) => {
    const res:any  = await Http({
      url: API_POST_USER_SEARCH,
      method: 'post',
      loading: false,
      returnAll: true,
      body:{
        session_id:sessionId,
        verifycode:varifyImg,
        idcardno:idCard
      }
    });
    return res;
  };
  // 页面滚动
  const handleScroll = () => {
    const $wrap = pageRef?.current as HTMLDivElement | null;
    const scrolltop = $wrap?.scrollTop || 0;
    if (scrolltop > 32) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };
  // tab切换
  const handleChangeTab = (tab: number) => {
    setActiveTab(tab);
  };
  const handleGoMbOpenAccount = () => {
    navigate('/mbOpenAccount');
  };
  const handleGoMbAccountSearch = () => {
    navigate('/mbSearchAccount');
  };

  // 渲染
  const renderMain = () => {
    return (
      <div className="main-container mbSearch">
        <div className="main-tabs">
          <div className={cs('tabs-item', activeTab === 0 ? 'active' : '')} onClick={() => handleChangeTab(0)}>
            <span>仿真开户</span>
          </div>
          <div className={cs('tabs-item', activeTab === 1 ? 'active' : '')} onClick={() => handleChangeTab(1)}>
            <span>仿真账号查询</span>
          </div>
        </div>
        {activeTab === 0 ? (
          <OpenAccount
            departmentList={departmentList}
            province={province}
            getMessageCode={getMessageCode}
            getValidImg={getValidImg}
            getOpenAccount={getOpenAccount}
          ></OpenAccount>
        ) : (
          <AccountSearch 
            getValidImg={getValidImg} 
            getOpenSearch={getOpenSearch}
          ></AccountSearch>
        )}
      </div>
    );
  };
  const renderMainMb = () => {
    return (
      <div className="main-container-mb">
        <div className='main-container-mb-card' onClick={handleGoMbOpenAccount}>
          <div className='card-title'>无仿真账户</div>
          <div className='card-inner'>
            <div className='card-left'>
              <span>立即开户</span>
              <img src={IMG_ORIGIN_ARROW} alt="arrow" className='img-arrow' />
            </div>
            <img src={IMG_CARD} alt="card" className='card-img' />
          </div>
        </div>
        <div className='main-container-mb-card search' onClick={handleGoMbAccountSearch}>
          <div className='card-title'>已有仿真账户</div>
          <div className='card-inner'>
            <div className='card-left'>
              <span>仿真账号查询</span>
              <img src={IMG_YELLOW_ARROW} alt="arrow" className='img-arrow' />
            </div>
            <img src={IMG_CARD_SEARCH} alt="card" className='card-img' />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="home-page" ref={pageRef}>
      <PcHeader isFixed={isFixed}></PcHeader>
      <PcBanner></PcBanner>
      {renderMain()}
      {renderMainMb()}
      <PcFooter></PcFooter>
    </div>
  );
};

export default Home;
