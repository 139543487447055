import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import { Button, Result } from 'antd';

const ErrorPage = () => {

  const handleBackHome = () => {
    window.location.href = 'https://www.gtjaqh.com';
  };

  return (
    <div className='error-page'>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={handleBackHome}>返回首页</Button>}
      />
    </div>
  );
};

export default ErrorPage;
