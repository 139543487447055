// 实现自己的 redux
import React, { Context, createContext, useReducer } from 'react';

export interface StateType {
  count: number;
}
export interface ActionType {
  type: string;
  payload?: StateType;
}
export interface RootContextType {
  rootObj?: StateType;
  setRootObj?: (params: ActionType) => void;
}

// 创建 context
export const RootContext: Context<RootContextType> = createContext({});

// 定义 actionType
export const RootStoreType = {
  UPDATE_COUNT: 'UPDATE_COUNT'
};

// 创建 reducer
const reducer = (state: StateType, action: any) => {
  switch(action.type) {
  case RootStoreType.UPDATE_COUNT:
    return {...state, ...action.payload};
  default:
    return state;
  }
};

// provider 组件
const initialState: StateType = {
  count: 0
};
export const RootStore = (props: any) => {
  const [rootObj, setRootObj] = useReducer(reducer, initialState);

  const providerObj = {
    rootObj,
    setRootObj
  };

  return (
    <RootContext.Provider value={providerObj}>
      {props.children}
    </RootContext.Provider>
  );
};
