import './index.scss';
import IMG_ACHIEVE from '../../../assets/imgs/achieve.png';

const OpenResult = () => {
  return (
    <div className="application">
      <img src={IMG_ACHIEVE} alt="" />
      <div className="achieve-text">
        <div className="achieve-tip">账号开通成功后，开户信息将发送至您预留的手机号</div>
        <div className="achieve-tel">营业部电话：95521</div>
      </div>
    </div>
  );
};

export default OpenResult;
