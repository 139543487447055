import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import { FRENDLY_LINKS, TERMS_AND_CONDITIONS, INFORMATION_PUBLICITY, URL_FILING, URL_BEIAN } from '../../config/hardCode';
import IMG_BEIAN from '../../assets/imgs/beian.png';
import IMG_SHANGHAI from '../../assets/imgs/shanghai.png';

const PcFooter = () => {
  const [year, setYear] = useState(2022);

  useEffect(() => {
    if (typeof window !== undefined) {
      const y = new Date().getFullYear();
      setYear(y);
    }
  }, []);

  const renderFooterTop = () => {
    return (
      <div className='footer-top'>
        <div className='footer-top-item'>
          <div className='footer-top-item-title'>条款说明</div>
          {TERMS_AND_CONDITIONS?.map((item) => {
            return (
              <div className='footer-top-item-link' key={item.name}>
                <a href={item.link} target='blank'>
                  {item.name}
                </a>
              </div>
            );
          })}
        </div>
        <div className='footer-top-item'>
          <div className='footer-top-item-title'>信息公示</div>
          {INFORMATION_PUBLICITY?.map((item) => {
            return (
              <div className='footer-top-item-link' key={item.name}>
                <a href={item.link} target='blank'>
                  {item.name}
                </a>
              </div>
            );
          })}
        </div>
        <div className='footer-top-item'>
          <div className='footer-top-item-title'>联系我们</div>
          <div className='footer-top-item-text'>
            <i className='gtjaqh icon-kefu'></i>
            <span>统一客服：95521/400-8888-666</span>
          </div>
          <div className='footer-top-item-text'>
            <i className='gtjaqh icon-zixunxuanzhong'></i>
            <span>在线咨询（8:00 - 24:00）</span>
          </div>
          <div className='footer-top-item-text'>
            <i className='gtjaqh icon-dianhua'></i>
            <span>前台电话（非业务咨询）：021-33038999</span>
          </div>
          <div className='footer-top-item-text'>
            <i className='gtjaqh icon-kefu1'></i>
            <span>夜班电话：021-33038775/021-33038776 (交易日20:30~次日2:30)</span>
          </div>
        </div>
      </div>
    );
  };
  const renderFooterBottom = () => {
    return (
      <div className='footer-bottom'>
        <div className='footer-bottom-top'>
          <div className='friend-link-wrap'>
            <div className='friend-link-show'>
              <span className='friend-link-show-title'>友情链接</span>
              <div className='friend-link-show-arrow'>
                <i className='gtjaqh icon-xia'></i>
              </div>
            </div>
            <div className='friend-link-list-wrap'>
              <ul className='friend-link-list'>
                {FRENDLY_LINKS?.map((item) => {
                  return (
                    <li className='friend-link-list-item' key={item.label}>
                      <a href={item.url} target='_blank' rel='noreferrer'>
                        {item.label || ''}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <img src={IMG_SHANGHAI} className='copyright-icon' alt='shanghai' />
          <span className='bottom-top-text'>上海市互联网违法和不良信息举报中心</span>
          <span className='bottom-top-text'>国泰君安期货官网现已支持</span>
          <span className='ipv6-icon'>IPv6</span>
        </div>
        <div className='footer-bottom-bottom'>
          <span className='bottom-bottom-text'>Copyright © 1999-{year} 国泰君安期货有限公司</span>
          <img src={IMG_BEIAN} className='beian-icon' alt='beian' />
          <a href={URL_BEIAN} target='_blank' rel='noreferrer'>
            <span className='bottom-bottom-text'>沪公网安备 31010602000411</span>
          </a>
          <a href={URL_FILING} target='_blank' rel='noreferrer'>
            <span className='bottom-bottom-text'>沪ICP备06059277号-1</span>
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className='pc-footer-wrap'>
      <div className='footer-inner'>
        {renderFooterTop()}
        {renderFooterBottom()}
      </div>
    </div>
  );
};

export default PcFooter;
