// export const BASE_URL = 'http://mid-inner.qiuer.net/middlecap-1.0/do';  // 测试环境地址 - 灵雀云
// export const BASE_URL = 'https://luna.qiuer.cc:30443/middlecap-1.0/do';  // 测试环境地址
// export const BASE_URL = 'https://luna.gtjaqh.cn:32443/middlecap-1.0/do';   // 仿真环境
export const BASE_URL = 'https://luna.gtjaqh.com/middlecap-1.0/do';   // 生产环境
// export const BASE_URL = 'http://mid-inner.gtjaqh.net:30007/middlecap-1.0/do';   // 生产环境 - 灵雀云

// 验证码图片
export const API_POST_PNG = '/6170.1';
// 仿真户查询
export const API_POST_USER_SEARCH = '/6170.2';
// 发送手机验证码
export const API_POST_SEND_CODE = '/6170.8';
// 仿真开户提交
export const API_POST_SUBMIT = '/6170.13';
// 营业部
export const API_POST_ALL_STORE = '/6170.15';
// 省市区数据
export const API_POST_CITY_DATA = '/6170.18';