import Png95521White from '../assets/imgs/95521_white.png';
import Png95521Blue from '../assets/imgs/95521_blue.png';

// 子公司
export const SUB_COMPANIES = [
  {
    label: '国泰君安证券股份有限公司',
    url: 'https://www.gtja.com/'
  },
  {
    label: '国泰君安证券资产管理有限公司',
    url: 'https://www.gtja.com/content/index/corp/gtjazg.html?t=external'
  },
  {
    label: '国泰君安风险管理有限公司',
    url: 'https://www.gtjafxgl.com/'
  },
  {
    label: '国泰君安创新投资有限公司',
    url: 'https://www.gtja.com/content/index/corp/gtjaiic.html?t=external'
  },
  {
    label: '国泰君安证裕投资有限公司',
    url: 'https://www.gtja.com/content/index/corp/gtjazytz.html?t=external'
  },
  {
    label: '国泰君安国际控股有限公司',
    url: 'https://www.gtja.com/content/index/corp/gtjai.html'
  },
  {
    label: '国泰君安证券（越南）股份公司',
    url: 'https://www.gtja.com/content/index/corp/gtjavn.html'
  }
];

// 快速访问
export const QUICK_NAVIGATION = [
  {
    id: 'calendartpl1',
    link: 'https://www.gtjaqh.com/pc/download',
    label: '软件下载',
    icon: 'gtjaqh icon-menu_calendartpl1'
  },
  {
    id: 'kaihu',
    link: 'https://cdn.gtjaqh.com/channel/gtjaqh.html',
    label: '快捷开户',
    icon: 'gtjaqh icon-kaihu'
  },
  {
    id: 'yingyeting',
    link: 'https://www.gtjaqh.com/pc/branch',
    label: '营业网点',
    icon: 'gtjaqh icon-yingyeting'
  },
  {
    id: '95521',
    link: '',
    label: '',
    alt: '95521',
    src: Png95521White,
    fixedSrc: Png95521Blue
  }
];

// 友情链接
export const FRENDLY_LINKS = [
  {
    label: '中国证券监督管理委员会',
    url: 'http://www.csrc.gov.cn/'
  },
  {
    label: '上交所股票期权投教专区',
    url: 'https://option.sse.com.cn/'
  },
  {
    label: '深交所股票期权投教专区',
    url: 'https://www.szse.cn/option/'
  },
  {
    label: '中国金融期货交易所',
    url: 'http://www.cffex.com.cn/'
  },
  {
    label: '上海期货交易所',
    url: 'https://www.shfe.com.cn/'
  },
  {
    label: '郑州商品交易所',
    url: 'http://www.czce.com.cn/'
  },
  {
    label: '大连商品交易所',
    url: 'http://www.dce.com.cn/'
  },
  {
    label: '中国期货业协会',
    url: 'http://www.cfachina.org/'
  },
  {
    label: '上海市期货同业公会',
    url: 'https://shanghaifa.org.cn/'
  },
  {
    label: '北京农村商业银行',
    url: 'https://www.bjrcb.com/pc/cn/index/index.shtml'
  },
  {
    label: '我的钢铁网',
    url: 'https://www.mysteel.com/'
  },
  {
    label: '搜狐期货',
    url: 'http://money.sohu.com/qihuo/'
  },
  {
    label: '金融界',
    url: 'http://futures.jrj.com.cn/'
  },
  {
    label: '99期货',
    url: 'http://www.99qh.com/'
  },
  {
    label: '金融网',
    url: 'http://www.financeun.com/'
  },
  {
    label: '中国证券网',
    url: 'https://www.cnstock.com/'
  },
  {
    label: '新浪网',
    url: 'https://finance.sina.com.cn/futuremarket/'
  },
  {
    label: '腾讯期货',
    url: 'https://new.qq.com/ch2/qihuo'
  },
  {
    label: '和讯网',
    url: 'https://www.hexun.com/'
  },
  {
    label: '中国化纤信息网',
    url: 'https://www.ccf.com.cn/'
  },
  {
    label: '钢之家',
    url: 'https://www.steelhome.cn/index.php'
  }
];

// 条款声明
export const TERMS_AND_CONDITIONS = [
  {
    name: '版权声明',
    link: 'https://www.gtjaqh.com/pc/c/copyright'
  },
  {
    name: '使用条款',
    link: 'https://www.gtjaqh.com/pc/c/terms'
  },
  {
    name: '合法网站声明',
    link: 'https://www.gtjaqh.com/pc/c/statement'
  }
];

// 信息公示
export const INFORMATION_PUBLICITY = [
  {
    name: '加入我们',
    link: 'https://www.gtjaqh.com/pc/c/hiring'
  },
  {
    name: '软件下载',
    link: 'https://www.gtjaqh.com/pc/download'
  },
  {
    name: '投诉与建议',
    link: 'https://www.gtjaqh.com/pc/c/complain'
  }
];

// 备案地址
export const URL_FILING = 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602000411';
export const URL_BEIAN = 'https://beian.miit.gov.cn';