import React, { useState, useEffect, useRef } from 'react';
import './assets/css/App.scss';
import Router from './config/router';
import { RootStore } from './context/rootContext';
import './assets/fonts/iconfont.css';
import useRem from './utils/rem';

function App() {
  useRem();

  return (
    <div className="App">
      <RootStore>
        <Router></Router>
      </RootStore>
    </div>
  );
};

export default App;
