import React from 'react';
import './index.scss';
import PngBanner from '../../../assets/imgs/simulate_open_account.jpg';

const PcBanner = () => {

  return (
    <div className='pc-banner-wrap'>
      <img src={PngBanner} alt="banner" className='banner-img' />
    </div>
  );
};

export default PcBanner;
