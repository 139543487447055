import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import { SUB_COMPANIES, QUICK_NAVIGATION } from '../../config/hardCode';
import LogoSvg from '../../assets/imgs/logo.svg';
import OneSvg from '../../assets/imgs/oneN.svg';

interface PropsType {
  isFixed?: boolean
}

const PcHeader = (props: PropsType) => {
  const { isFixed } = props;

  const renderTopLeft = () => {
    return (
      <div className='top-inner-left'>
        <span>集团旗下公司</span>
        <i className='gtjaqh icon-xia' />
        {renderSubCompany()}
      </div>
    );
  };
  const renderSubCompany = () => {
    return (
      <ul className='sub-company-wrap'>
        {SUB_COMPANIES?.map((item, ind: number) => {
          return (
            <li className='sub-company-item' key={ind}>
              <a className='sub-company-item-link' href={item.url} target='_blank' rel='noreferrer'>
                {item.label}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };
  const renderTopRight = () => {
    return (
      <div className='top-inner-right'>
        {QUICK_NAVIGATION?.map((item: any) => (
          <a
            key={item.id}
            className='right-item'
            href={item.link || null}
            target={item.link ? '_blank' : '_self'}
            rel='noreferrer'
          >
            {item.src ? (
              <img className='img95521' src={item.src} alt={item.alt} />
            ) : (
              <>
                <i className={item.icon} />
                <span>{item.label}</span>
              </>
            )}
          </a>
        ))}
      </div>
    );
  };
  const renderLogo = () => {
    return (
      <div className={`header-logo ${isFixed ? 'fixed-logo' : ''}`}>
        <div className='fixed-inner'>
          <a href='https://www.gtjaqh.com' className='logo-wrap'>
            <img src={LogoSvg} alt='logo' className='logo-img' />
          </a>
          {!isFixed && (
            <a href='https://www.gtjaqh.com' className='member-one-wrap'>
              <img src={OneSvg} alt='MemberOne' className='logo-one' />
            </a>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='pc-header-wrap'>
      <div className='header-top'>
        <div className='top-inner'>
          {renderTopLeft()}
          {renderTopRight()}
        </div>
      </div>
      {renderLogo()}
    </div>
  );
};

export default PcHeader;
