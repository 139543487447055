import React, { ChangeEvent, useEffect, useState } from 'react';
import './index.scss';
import { Form, Button, message, Input } from 'antd';
import { OpenSearchPropsType } from './interface';
import Guid from '../../../utils/guid';

const AccountSearch = (props: OpenSearchPropsType) => {
  const { getValidImg, getOpenSearch } = props;
  const [resTip, setResTip] = useState(''); //显示查询结果
  const [accountId, setAccountId] = useState('');
  const [imageStream, setImageStream] = useState('');
  const [sessionId, setSessionId] = useState((Guid.NewGuid() as any).ToString());

  useEffect(() => {
    handleGetVerifyImg();
  }, []);

  // 查询事件
  const handleSearch = async (values: any) => {
    const searchResult = await getOpenSearch(sessionId, values.idCard, values.varifyImg);
    // 点击获取验证码后重新生成验证码
    handleGetVerifyImg();
    if (searchResult?.code === 0) {
      setAccountId(searchResult?.data?.investorid || '');
      setResTip('申请成功，仿真账号为' + searchResult?.data?.investorid);
    } else {
      setResTip(searchResult?.msg || '查询失败');
    }
  };
  const handleGetVerifyImg = async () => {
    const imgData: string = await getValidImg(sessionId);
    if (imgData) {
      setImageStream('data:image/jpg;base64,' + imgData);
    } else {
      message.error('获取图片验证码失败');
    }
  };

  return (
    <div className="account-search-wrap">
      <Form id="open-form" autoComplete="off" onFinish={handleSearch}>
        <Form.Item
          label="身份证号"
          className="formItem"
          name="idCard"
          rules={[{ required: true, message: '请输入身份证号码！' }]}
        >
          <Input bordered={false} placeholder="请输入身份证号码" />
        </Form.Item>
        <Form.Item
          className="valid-pic"
          label="图形验证码"
          name="varifyImg"
          rules={[{ required: true, message: '请输入图形验证码！' }]}
        >
          <div>
            <Input bordered={false} placeholder="请输入图形验证码" />
            <img className="valid-img" src={imageStream} alt="验证码图片" onClick={handleGetVerifyImg} />
          </div>
        </Form.Item>
        <Form.Item className="submit-item">
          <Button type="primary" htmlType="submit">
            查询
          </Button>
        </Form.Item>
      </Form>
      {resTip ? <div className="searchResult">{resTip}</div> : null}
    </div>
  );
};

export default AccountSearch;
