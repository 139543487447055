import React, { useRef, useEffect, useState, ChangeEvent, forwardRef, LegacyRef } from 'react';
import cs from 'classnames';
import './index.scss';
import { MbFormItemPropsType } from './interface';

const MbFormItem = forwardRef((props: MbFormItemPropsType, ref: LegacyRef<HTMLDivElement>) => {
  const {
    className,
    label,
    value,
    tip,
    required,
    isMutl,
    placeholder,
    hasImg,
    imgData,
    parten,
    setValue,
    refreshImgData
  } = props;
  const [showTip, setShowTip] = useState(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = e.target.value;
    setValue(val);
  };
  const handleInputBlur = () => {
    if (!required)  {
      setShowTip(false);
      return;
    }
    const reg = parten || /[\s\S]+/;
    if (!reg.test(value)) {
      setShowTip(true);
      return;
    }
    setShowTip(false);
  };

  return (
    <div className={cs('mb-form-item-wrap', hasImg ? 'hasImg' : '', isMutl ? 'mutl' : '', className || '')} ref={ref}>
      <div className='mb-form-input'>
        <span className='label'>{label || '输入'}</span>
        {
          isMutl ? (
            <textarea
              value={value || ''}
              placeholder={placeholder || ''}
              rows={2}
              onChange={(e) => handleInputChange(e)}
              onBlur={handleInputBlur}
            />
          ) : (
            <input
              value={value || ''}
              placeholder={placeholder || ''}
              onChange={(e) => handleInputChange(e)}
              onBlur={handleInputBlur}
            />
          )
        }
        {
          hasImg ? (
            <div className='form-item-img-wrap' onClick={refreshImgData}>
              <img src={imgData} alt="" className='form-item-img' />
            </div>
          ) : null
        }
      </div>
      {showTip ? <div className='mb-form-tip'>{tip}</div> : null }
    </div>
  );
});

export default MbFormItem;