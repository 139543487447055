import { HashRouter, Routes, Route } from 'react-router-dom';
import Home from '../pages/home';
import Page404 from '../pages/404';
import PageMbOpen from '../pages/mbOpen';
import PageMbSearch from '../pages/mbSearch';

const Router = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/mbOpenAccount' element={<PageMbOpen />}/>
        <Route path='/mbSearchAccount' element={<PageMbSearch />}/>
        <Route path='*' element={<Page404 />}/>
      </Routes>
    </HashRouter>
  );
};

export default Router;
