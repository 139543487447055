import React, { ChangeEvent, useEffect, useState } from 'react';
import './index.scss';
import OpenResult from '../openResult';
import cs from 'classnames';
import { Button, Select, Space, message } from 'antd';
import { Picker, CascadePicker } from 'antd-mobile';
import { OpenAccountPropsType, OpenAccountSubmitParams } from './interface';
import { DepartmentItemResultType, ProvinceResultType } from '../../../pages/home/interface';
import Guid from '../../../utils/guid';
import { PickerValue, PickerValueExtend } from 'antd-mobile/es/components/picker-view';

let timer: any; //验证码定时器
const OpenAccount = (props: OpenAccountPropsType) => {
  const { departmentList, province, getMessageCode, getValidImg, getOpenAccount } = props;
  const [imageStream, setImageStream] = useState('');
  const [sessionId, setSessionId] = useState((Guid.NewGuid() as any).ToString());
  const [tab, setTab] = useState(0); // 当前步骤
  const [userName, setUserName] = useState(''); // 用户名
  const [userNameTip, setUserNameTip] = useState(''); // 用户名提示
  const [idCard, setIdCard] = useState(''); //身份证号
  const [idNumberTip, setIdNumberTip] = useState(''); // 身份证号提示
  const [phoneNum, setPhoneNum] = useState(''); // 手机号
  const [phoneNumTip, setPhoneNumTip] = useState(''); // 手机号提示
  const [varifyImg, setVarifyImg] = useState(''); // 图片验证码
  const [validImgTip, setValidImgTip] = useState(''); // 图形验证码提示
  const [messageCode, setMessageCode] = useState(''); // 短信验证码
  const [messageCodeTip, setMessageCodeTip] = useState(''); // 短信验证码提示
  const [email, setEmail] = useState(''); // 电子邮箱
  const [emailTip, setemailTip] = useState(''); // 电子邮箱提示
  const [detailedAddress, setDetailedAddress] = useState(''); // 详细地址
  const [detailedAddressTip, setDetailedAddressTip] = useState(''); // 详细地址提示
  const [provinceValue, setProvinceValue] = useState(''); // 省
  const [provinceId, setProvinceId] = useState(''); // 省编号
  const [provinceTip, setProvinceTip] = useState(''); // 省提示
  const [cityValue, setCityValue] = useState(''); // 市
  const [cityId, setCityId] = useState(''); // 市编号
  const [cityTip, setCityTip] = useState(''); // 市提示
  const [departmentId, setDepartmentId] = useState(''); // 营业部编号
  const [mbDepartmentName, setMbDepartmentName] = useState(''); // 移动端营业部名称
  const [departmentTip, setDepartmentTip] = useState(''); // 营业部提示
  const [second, setSecond] = useState(59); //倒计时秒数
  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false); // 获取验证码按钮是否可点击，false可点击
  const [cityOptions, setCityOptions] = useState<ProvinceResultType[]>([]); // pc端城市选择的选项
  const [mobileAddrVisible, setMobileAddrVisible] = useState(false); // 移动端地址选择
  const [mobileStoreVisible, setMobileStoreVisible] = useState(false); // 移动端营业部选择
  const [mbCityData, setMbCityData] = useState<ProvinceResultType[]>([]);
  const [mbStoreData, setMbStoreData] = useState<ProvinceResultType[]>([]);
  const { Option } = Select;

  // 初始化时清除定时器
  useEffect(() => {
    handleGetVerifyImg();
    clearInterval(timer);
    return () => clearInterval(timer);
  }, []);
  // 监听秒数的变动
  useEffect(() => {
    if (second < 0 || second > 60) {
      // 定时器超过时间后，可以重新发送验证码
      clearInterval(timer);
      // 可点击
      setBtnDisabled(false);
      setPhoneDisabled(false);
      setSecond(60);
    }
  }, [second]);
  useEffect(() => {
    const arr = [...province];
    arr.forEach((item) => {
      item.children = item.nodes || [];
    });
    setMbCityData(arr);
  }, [province]);
  useEffect(() => {
    const arr: ProvinceResultType[] = [];
    departmentList.forEach((item) => {
      arr.push({
        value: item.departmentid,
        label: item.departmentname,
      });
    });
    setMbStoreData(arr);
  }, [departmentList]);

  // 获取手机验证码
  const handleSendCode = async() => {
    if (!phoneNum || !/^1[\d]{10}$/.test(phoneNum)) {
      // 校验手机号并提示
      setPhoneNumTip('请输入正确的手机号！');
      return;
    }
    if (!varifyImg) {
      // 校验varifyImg并提示
      setValidImgTip('请输入图形验证码！');
      return;
    }
    const messageResult = await getMessageCode(sessionId, phoneNum, varifyImg);
    if (!messageResult) {
      // 点击获取验证码后重新生成图片验证码
      handleGetVerifyImg();
      return;
    }
    // 倒计时递减
    timer = setInterval(() => setSecond((pre) => pre - 1), 1000);
    // 不可点击
    setBtnDisabled(true);
    setPhoneDisabled(true);
  };
  const handleGetVerifyImg = async () => {
    const imgData: string = await getValidImg(sessionId);
    if (imgData) {
      setImageStream('data:image/jpg;base64,' + imgData);
    } else {
      message.error('获取图片验证码失败');
    }
  };
  // 表单提交
  const handleSubmit = async (values: any) => {
    if (!userName || userName.length < 2 || userName.length > 4 || !/^[\u4e00-\u9fa5]*$/.test(userName)) {
      setUserNameTip('请输入正确的用户名');
      return;
    }
    if (!idCard) {
      setIdNumberTip('请输入身份证号！');
      return;
    }
    if (!phoneNum || !/^1[\d]{10}$/.test(phoneNum)) {
      setPhoneNumTip('请输入正确的手机号！');
      return;
    }
    if (!varifyImg) {
      setValidImgTip('请输入图形验证码！');
      return;
    }
    if (!messageCode) {
      setMessageCodeTip('请输入短信验证码！');
      return;
    }
    if (!email || !/^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/.test(email)) {
      setemailTip('请输入正确的电子邮箱！');
      return;
    }
    if (!provinceValue || !provinceId) {
      setProvinceTip('请选择省份');
      return;
    }
    if (!cityValue || !cityId) {
      setCityTip('请选择城市');
      return;
    }
    if (!detailedAddress) {
      setDetailedAddressTip('请输入50字符以内的详细地址！');
      return;
    }
    if (!departmentId) {
      setDepartmentTip('请选择营业部');
      return;
    }

    const obj: OpenAccountSubmitParams = {
      varifyImg,
      messageCode,
      userName,
      idCard,
      provinceValue,
      provinceId,
      cityValue,
      cityId,
      detailedAddress,
      phoneNum,
      departmentId,
      sessionId,
    };
    const requestResult = await getOpenAccount(obj);
    if (requestResult) {
      setTab(1);
    } else {
      handleGetVerifyImg();
    }
  };
  const handleProvinceChange = (value: { value: string; label: string }) => {
    let arr: ProvinceResultType[] = [];
    province.forEach((item: any) => {
      if (item.value === value.value) {
        arr = item.nodes || [];
      }
    });
    setCityOptions(arr);
    setProvinceValue(value.label);
    setProvinceId(value.value);
    setProvinceTip('');
  };
  const handlecityChange = (value: { value: string; label: string }) => {
    setCityValue(value.label);
    setCityId(value.value);
    setCityTip('');
  };
  const departmentChange = (value: { value: string; label: string }) => {
    setDepartmentId(value.value);
    setDepartmentTip('');
  };

  const handleMobileAddrVisible = (type: boolean) => {
    setMobileAddrVisible(type);
  };
  // 公共input
  const handleInputChange = (type: string, e: ChangeEvent<HTMLInputElement>) => {
    switch (type) {
    case 'userName':
      setUserName(e?.target?.value || '');
      setUserNameTip('');
      break;
    case 'idCard':
      setIdCard(e?.target?.value || '');
      setIdNumberTip('');
      break;
    case 'phoneNum':
      setPhoneNum(e?.target?.value || '');
      setPhoneNumTip('');
      break;
    case 'varifyImg':
      setVarifyImg(e?.target?.value || '');
      setValidImgTip('');
      break;
    case 'messageCode':
      setMessageCode(e?.target?.value || '');
      setMessageCodeTip('');
      break;
    case 'email':
      setEmail(e?.target?.value || '');
      setemailTip('');
      break;
    case 'detailedAddress':
      setDetailedAddress(e?.target?.value || '');
      setDetailedAddressTip('');
      break;
    }
  };
  const handleMbCityConfirm = (val: PickerValue[], extend: PickerValueExtend) => {
    const data: any = extend.items || [];
    if (!data.length) return;
    setProvinceId(data[0]?.value || '');
    setProvinceValue(data?.[0]?.label || '');
    setCityId(data?.[1]?.value || '');
    setCityValue(data?.[1]?.label || '');
    setProvinceTip('');
    setCityTip('');
  };
  const handleMobileStoreVisible = (type: boolean) => {
    setMobileStoreVisible(type);
  };
  const handleMbStoreConfirm = (val: PickerValue[]) => {
    setDepartmentId(val?.[0] || '');
    let depName: string = '';
    departmentList.forEach((item) => {
      if (item.departmentid === val[0]) {
        depName = item.departmentname || '';
      }
    });
    setMbDepartmentName(depName);
    setDepartmentTip('');
  };

  // 进度条
  const renderStep = () => {
    return (
      <div className={cs('inner-step', ' clearFloat')}>
        <div className="currentProgress f1">
          <span className="step-text">输入申请信息</span>
          {/* <br></br> */}
          <i className="gtjaqh icon-youjian"></i>
        </div>
        <div className={tab === 1 ? 'currentProgress' : 'fr'}>
          <span className="step-text">完成申请</span>
          <i className="gtjaqh icon-youjian"></i>
        </div>
      </div>
    );
  };
  // account表格
  const renderAccountForm = () => {
    return (
      <div className={tab === 1 ? 'form-hide' : 'form-show'} id="account-form">
        <div className="tip">
          <label>以下所有项为必填：</label>
        </div>
        <div className="formItem user-name-wrap">
          <label className="formItem-label">姓名</label>
          <span>：</span>
          <input
            className="formItem-input"
            type="text"
            placeholder="请输入2到4个中文字符"
            value={userName}
            onChange={(e) => handleInputChange('userName', e)}
          />
          {userNameTip ? <div className="formItem-tip">{userNameTip}</div> : null}
        </div>
        <div className="formItem id-number-wrap">
          <label className="formItem-label">身份证号</label>
          <span>：</span>
          <input
            className="formItem-input"
            type="text"
            placeholder="请输入身份证号码"
            value={idCard}
            onChange={(e) => handleInputChange('idCard', e)}
          />
          {idNumberTip ? <div className="formItem-tip">{idNumberTip}</div> : null}
        </div>
        <div className="formItem phone-number-wrap">
          <label className="formItem-label">手机号</label>
          <span>：</span>
          <input
            disabled={phoneDisabled}
            className="formItem-input"
            type="text"
            placeholder="请输入手机号"
            value={phoneNum}
            onChange={(e) => handleInputChange('phoneNum', e)}
          />
          {phoneNumTip ? <div className="formItem-tip">{phoneNumTip}</div> : null}
        </div>
        <div className="formItem valid-pic">
          <label className="formItem-label">图形验证码</label>
          <span>：</span>
          <input
            className="formItem-input"
            type="text"
            placeholder="请输入图形验证码！"
            value={varifyImg}
            onChange={(e) => handleInputChange('varifyImg', e)}
          />
          <img className="valid-img" src={imageStream} alt="验证码图片" onClick={handleGetVerifyImg} />
          {validImgTip ? <div className="formItem-tip">{validImgTip}</div> : null}
        </div>
        <div className="formItem valid-text">
          <label className="formItem-label">短信验证码</label>
          <span>：</span>
          <input
            className="formItem-input"
            type="text"
            placeholder="请填写短信验证码"
            value={messageCode}
            onChange={(e) => handleInputChange('messageCode', e)}
          />
          <button
            className="validBtn"
            disabled={btnDisabled}
            onClick={() => {
              handleSendCode();
            }}
          >
            {!btnDisabled ? '获取验证码' : `${second}s后重发`}
          </button>
          {messageCodeTip ? <div className="formItem-tip">{messageCodeTip}</div> : null}
        </div>
        <div className="formItem email-wrap">
          <label className="formItem-label">电子邮箱</label>
          <span>：</span>
          <input
            className="formItem-input"
            type="text"
            placeholder="请准确填写邮箱，以便收到最新的资讯！"
            value={email}
            onChange={(e) => handleInputChange('email', e)}
          />
          {emailTip ? <div className="formItem-tip">{emailTip}</div> : null}
        </div>
        <div className="formItem pcAddress">
          <span className="formItem-label">地址:</span>
          <Space>
            <Select
              labelInValue
              style={{ width: 155, marginLeft: '15px', fontSize: '13.5px', color: '#666' }}
              onChange={handleProvinceChange}
              options={province}
              placeholder="请选择省份"
            />
            <Select
              labelInValue
              style={{ width: 155, fontSize: '13.5px', color: '#666' }}
              onChange={handlecityChange}
              options={cityOptions}
              placeholder="请选择城市"
            />
          </Space>
          {provinceTip || cityTip ? <div className="formItem-tip">{provinceTip || cityTip}</div> : null}
        </div>
        <div className="formItem mbAddress">
          <label className="formItem-label">地址</label>
          <span>：</span>
          <span>{renderAddress()}</span>
        </div>
        <div className="formItem detailed-address-wrap">
          <label className="formItem-label">详细地址</label>
          <span>：</span>
          <input
            className="formItem-input"
            type="text"
            placeholder="请输入街道门牌号等"
            value={detailedAddress}
            onChange={(e) => handleInputChange('detailedAddress', e)}
          />
          {detailedAddressTip ? <div className="formItem-tip">{detailedAddressTip}</div> : null}
        </div>
        <div className="formItem pcDepartment">
          <label className="formItem-label">拟开户营业部</label>
          <span>：</span>
          <Select
            showSearch
            labelInValue
            style={{ width: 320, marginLeft: '3%', color: '#666', fontSize: '13.5px' }}
            placeholder="请选择或搜索开户营业部"
            optionFilterProp="children"
            onChange={departmentChange}
          >
            {departmentList?.map((item: any) => (
              <Option key={item.departmentid}>{item.departmentname}</Option>
            ))}
          </Select>
          {departmentTip ? <div className="formItem-tip">{departmentTip}</div> : null}
        </div>
        <div className="formItem mbDepartment">
          <label className="formItem-label">拟开户营业部</label>
          <span>：</span>
          <span>{renderStore()}</span>
          {departmentTip ? <div className="formItem-tip">{departmentTip}</div> : null}
        </div>
        <div className="submitItem">
          <Button type="primary" htmlType="submit" onClick={() => handleSubmit(1)}>
            提交
          </Button>
        </div>
      </div>
    );
  };
  // 移动端地址
  const renderAddress = () => {
    return (
      <>
        <input
          className="mb-city-selector"
          readOnly
          onClick={() => handleMobileAddrVisible(true)}
          placeholder="请选择省市"
          value={provinceValue + cityValue}
        />
        <CascadePicker
          title="省市选择"
          options={mbCityData}
          visible={mobileAddrVisible}
          onClose={() => handleMobileAddrVisible(false)}
          onConfirm={handleMbCityConfirm}
        />
        {provinceTip || cityTip ? <div className="formItem-tip">{provinceTip || cityTip}</div> : null}
      </>
    );
  };
  const renderStore = () => {
    return (
      <>
        <input
          value={mbDepartmentName}
          className="mb-city-selector"
          readOnly
          onClick={() => handleMobileStoreVisible(true)}
          placeholder="请选择营业部"
        />
        <Picker
          title="营业部选择"
          columns={[mbStoreData]}
          visible={mobileStoreVisible}
          onClose={() => handleMobileStoreVisible(false)}
          onConfirm={handleMbStoreConfirm}
        />
        {departmentTip ? <div className="formItem-tip">{departmentTip}</div> : null}
      </>
    );
  };

  return (
    <div className=" open-account-wrap">
      <div className="open-status">
        {renderStep()}
        {/* 表格 */}
        {renderAccountForm()}
        {tab === 1 ? <OpenResult></OpenResult> : null}
      </div>
    </div>
  );
};

export default OpenAccount;
