// 全局加载loading
const GlobalLoading = {
  count: 0,
  create() {
    if (this.count > 0) return;
    const $el = document.createElement('div');
    $el.id = 'global-loading-wrap';
    const $elInner = document.createElement('div');
    $elInner.className = 'global-loading-inner';
    for (let i = 0; i < 4; i++) {
      const $elLoadingItem = document.createElement('span');
      $elLoadingItem.className = 'global-loading-inner-item';
      $elInner.appendChild($elLoadingItem);
    }
    $el.appendChild($elInner);
    document.body.appendChild($el);
  },
  remove() {
    this.count--;
    if (this.count <= 0) {
      this.count = 0;
      const $el = document.getElementById('global-loading-wrap');
      $el?.remove();
    }
  },
  clear() {
    this.count = 0;
    const $el = document.getElementById('global-loading-wrap');
    $el?.remove();
  },
};

export default GlobalLoading;
