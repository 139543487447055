import axios from 'axios';
import { RequestParamsType, ResultType, Method } from './interface';
import GlobalLoading from '../globalLoading';
import { notification } from 'antd';
import { BASE_URL } from '../../config/api';

const handleParams = (params: RequestParamsType): RequestParamsType => {
  const newParams = {...params};
  const method = params.method?.toLocaleLowerCase() as Method;
  const param = params.param || {};
  if (method === 'get' && param instanceof Object) {
    newParams.url += '?';
    for(let k in param) {
      newParams.url += `${k}=${param[k]}&`;
    }
    // 去除多余的&符号
    if (newParams.url.slice(newParams.url.length - 1) === '&') {
      newParams.url = newParams.url.slice(0, newParams.url.length - 1);
    }
  }
  return newParams;
};

const handleStatus = (result: ResultType): boolean => {
  if (result?.code === 0) {
    return true;
  }
  return false;
};

const Http = (params: RequestParamsType): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      const method = params.method?.toLocaleLowerCase() as Method;
      const newParams = handleParams(params);
      const instance = axios.create();
      // loading
      params.loading && GlobalLoading.create();
      // 请求拦截
      instance.interceptors.request.use((config: any) => {
        if (newParams.formdata) {
          const headers = config?.headers;
          if (headers) {
            headers['Content-Type'] = 'multipart/form-data';
          }
        }
        return config;
      });
      // 响应拦截
      instance.interceptors.response.use(
        (res: any) => {
          if (res?.status === 200) {
            return res.data || '';
          }
          return '';
        },
        (err: any) => {
          console.error(err);
          return reject(err);
        }
      );
      instance({
        baseURL: BASE_URL,
        url: newParams.url,
        method: method,
        headers: newParams.headers || {},
        data: newParams.body,
      }).then((res: any) => {
        params.loading && GlobalLoading.remove();
        const flag = handleStatus(res);
        if (!flag && !params.checkLogin) {
          if (params.returnAll) {
            resolve(res);
          } else {
            notification.error({
              message: res?.msg || '请求失败'
            });
            resolve('');
          }
        }
        if (newParams.returnAll) {
          resolve(res);
        } else {
          resolve(res.data);
        }
      }).catch((err: any) => {
        params.loading && GlobalLoading.remove();
        console.error(err);
        reject(err);
      });
    } catch (e) {
      console.error(e);
      GlobalLoading && GlobalLoading.remove();
      notification.error({
        message: '请求错误',
        description: JSON.stringify(e),
      });
    }
  });
};

export default Http;
